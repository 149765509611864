import { GROWERS_TUBS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Tubs",
      "search-by": "Code,Location",
      "ph-search-by": "Search by keywords"
    }
  }, [_c("text-field", {
    key: "Code",
    attrs: {
      "data-index": "code",
      "title": "Tub Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Location",
    attrs: {
      "data-index": "location",
      "title": "Location",
      "sorter": true
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListTubs"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListTubs = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-tubs"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.tubs",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/tubs/create",
      "edit-route": "/watties-grower/tubs/:id",
      "page": _vm.page
    }
  }, [_c("list-tubs")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Tubs",
  components: {
    ListTubs
  },
  data() {
    return {
      ListTubs,
      apiUrl,
      page: GROWERS_TUBS,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "tubs",
          title: "Tubs",
          path: "/watties-grower/tubs"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
